import React from 'react';
import '../Styles/BlinkingBeacon.css';

const BlinkingBeacon = () => {
  return (
    <div className="beacon">
      <div className="beacon-dot"></div>
    </div>
  );
};

export default BlinkingBeacon;