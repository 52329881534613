import React from 'react';
import Marquee from "react-fast-marquee";
import '../Styles/Banner.css';

const Banner = () => {
  return (
    <div className="banner">
      <Marquee className='bannertext'>
        <div className="">$retail is here !!!</div>
        <div className="">$retail is here !!!</div>
        <div className="">$retail is here !!!</div>
        <div className="">$retail is here !!!</div>
      </Marquee>   
    </div>
  );
};

export default Banner;