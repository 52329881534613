import React, { useState } from 'react';
import { CopyToClipboard as CopyToClipboardButton } from 'react-copy-to-clipboard';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import "../Styles/CopyToClipboard.css";

const CopyToClipboard = ({ children, value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="copy-to-clipboard">
      <CopyToClipboardButton text={value} onCopy={handleCopy}>
        <div data-tooltip-id="tooltip">
          {children}
        </div>
      </CopyToClipboardButton>
      <Tooltip id="tooltip" place="top" effect="solid">
        {copied ? 'Copied!' : 'Copy to Clipboard'}
      </Tooltip>
    </div>
  );
};

export default CopyToClipboard;