import React from 'react';
import "../Styles/Gallery.css";


const Gallery = () => {
  const images = [
    { id: 1, src: '/meme1.png', alt: 'Image 1' },
    { id: 2, src: '/meme2.png', alt: 'Image 2' },
    { id: 3, src: '/meme3.png', alt: 'Image 3' },
    { id: 4, src: '/meme4.png', alt: 'Image 4' },
    { id: 5, src: '/meme5.png', alt: 'Image 5' },
    { id: 6, src: '/meme6.png', alt: 'Image 6' },
    { id: 7, src: '/meme7.png', alt: 'Image 7' },
    { id: 8, src: '/meme8.png', alt: 'Image 8' },
    { id: 9, src: '/meme9.png', alt: 'Image 9' },
    { id: 10, src: '/meme10.png', alt: 'Image 10' },
    { id: 11, src: '/meme11.png', alt: 'Image 11' },
    { id: 12, src: '/meme12.png', alt: 'Image 12' },
  

    // Add more image objects as needed
  ];

  return (
    <div className="gallery">
      {images.map((image) => (
        <div key={image.id} className="gallery-item">
          <img src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
  );
};

export default Gallery;