import React, { useState, useEffect } from "react";
import "../Styles/Study.css";
import BlinkingBeacon from './BlinkingBeacon';

const Study = () => {

  const dates = [
    "8TH DEC, 2017",
    "MAY, 2021",
    "OCT, 2021",
    "8TH DEC, 2017",
    "MAY, 2021",
  ];

  const [currentDateIndex, setCurrentDateIndex] = useState(0);
  const [displayText, setDisplayText] = useState("");
  

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateIndex((prevIndex) => (prevIndex + 1) % dates.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const currentDate = dates[currentDateIndex];
    let currentIndex = 0;

    const typingEffect = setInterval(() => {
      setDisplayText(currentDate.slice(0, currentIndex));
      currentIndex++;

      if (currentIndex > currentDate.length) {
        clearInterval(typingEffect);
      }
    }, 100);

    return () => {
      clearInterval(typingEffect);
    };
  }, [currentDateIndex]);

  return (
    <div className="studyContainer">
      

      <div className="imageContainer">
        <div className="pulsating-image studyImageBox studyImageBox1">
          <img
            loading="eager"
            alt="study1"
            src="/study1.png"
            className="studyImage1"
          />
        </div>

        <div className="pulsating-image studyImageBox studyImageBox2">
          <img
            loading="eager"
            alt="study"
            src="/study2.png"
            className="studyImage2"
          />
        </div>

        <div className=" pulsating-image studyImageBox studyImageBox3">
          <img
            loading="eager"
            alt="study"
            src="/study3.png"
            className="studyImage3"
          />
        </div>

        <div className="pulsating-image studyImageBox studyImageBox4">
          <img
            loading="eager"
            alt="study"
            src="/study4.png"
            className="studyImage4"
          />
        </div>

        <div className="pulsating-image studyImageBox studyImageBox5">
          <img
            loading="eager"
            alt="study"
            src="/study5.png"
            className="studyImage5"
          />
        </div>
      </div>

      <div className="studyText">
        <h2 className="study-header">Study <span className="glitchText">{displayText}</span></h2>

        <p className="study-paragraph">every time coinbase hit rank #1 on app store marked the top of the bull run</p>

        <h2 className="study-header study-header-blue">Coinbase<br></br>Appstore Rank</h2>

        <h1 className="study-cta">#224</h1>

        <div className="live"><BlinkingBeacon />LIVE</div>

        <p className="study-paragraph">when this is #1, the top is in</p>
      </div>

    </div>
  );
};

export default Study;
