import React from "react";
import "../Styles/Retail.css";
import CopyToClipboard from "./CopyToClipboard";

const Retail = () => {
  const valueToCopy = "0xB94A8Fd4A1086b08F2e260B00D5aA5448D7FD381";

  return (
    <div className="retail-container">
      <div className="retail-top">
        <div className="retail-top-img-box">
          <img
            loading="eager"
            alt="$retail is here"
            src="/retail.svg"
            className="retail-img"
          />
        </div>

        <p className="retail-finally">
          finally a walmart for crypto. for meth heads and scooter people,
          shitcoins like ripped open packages fallen off the shelves, no lines,
          no register, but lots of trash and noise, diet coke and mountain dew
          drinkers, no one in sight to make you physically recoil, or stench
          except for your own. it's what the people want and need. no more
          concern about trading naked, everyone is naked and buying the most
          disgusting combinations of shit you can imagine, they can shout
          whatever they want without fear of mysterious figures who are
          undersized for security guard and underarmed for policeman chasing
          you, tackling you, knocking you out and replacing you in a bare cell
          with someone named cyrus.
        </p>

        <div className="retail-tokenomics">
          <div className="retail-flex">
            <h1>1B $RETAIL</h1>
            <h1>Contract Renounced</h1>
          </div>

          <div className="retail-flex">
            <h1>100% in LP</h1>
          </div>

          <div className="retail-flex">
            <h1>0 TAX</h1>
            <h1>LP Burnt</h1>
          </div>
        </div>
      </div>

      <div className="retail-bottom">
        <div className="retail-img-box">
          <img
            loading="eager"
            alt="$retail is here"
            src="/before.svg"
            className="retail-img-1"
          />
        </div>

        <CopyToClipboard value={valueToCopy}>
          <div className="retail-img-box">
            <img
              loading="eager"
              alt="$retail is here"
              src="/ca.svg"
              className="retail-img-2"
            />
          </div>
        </CopyToClipboard>

        <a href="https://app.uniswap.org/swap?outputCurrency=0xB94A8Fd4A1086b08F2e260B00D5aA5448D7FD381&inputCurrency=ETH&chain=base" target="_blank"  rel="noreferrer noopener">

        <div className="retail-img-swap">
          <img
            loading="eager"
            alt="$retail is here"
            src="/swap.svg"
            className="retail-img-3"
          />
        </div>
        </a>

      </div>
    </div>
  );
};

export default Retail;
