import React, { useState, useEffect } from "react";
import "./App.css";
// import NavBar from "./Components/NavBar";
// import Hero from "./Components/Hero";
// import Study from "./Components/Study";
import Video from "./Components/Video";
import Banner from "./Components/Banner";
// import LoadingScreen from "./Components/LoadingScreen";
import Study from "./Components/Study";
import Retail from "./Components/Retail";
import Gallery from "./Components/Gallery";

function App() {
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const handleLoad = () => {
  //     setIsLoading(false);
  //   };

  //   window.addEventListener("load", handleLoad);

  //   return () => {
  //     window.removeEventListener("load", handleLoad);
  //   };
  // }, []);

  return (
    <div className="App">
      
        <>
          <Banner></Banner>
          <Video></Video>
          <Study></Study>
          <Retail></Retail>
          <Gallery></Gallery>
        </>

    </div>
  );
}

export default App;